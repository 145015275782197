.Navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.NavigationBar {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1030;
    background-color: rgba(0,0,0,0.1);
}