.GamesItem {
    padding-bottom: 20px;
    font-size: 15px;
}

.GamesItem p,
.GamesItem h3 {
    max-width: 350px;
    margin: 0 auto;
}

.GamesItem h3 {
    padding-bottom: 10px;
}

.GamesFlex {
    display: flex;
    align-items: center;
}

.GamesItem img {
    border-radius: 20%;
    max-width: 300px;
    margin: 0 auto;
    cursor: pointer;
}

.GamesItem img:hover {
    transition: all .5s;
    box-shadow: 0 3px 10px rgb(255 255 255 / 30%);
}

.GamesImage {
    display: flex;
    align-items: center;
    text-align: center;
}

.GamesItem:nth-child(odd) .GamesImage {
    order: 2;
}

@media all and (max-width: 1100px) {
    .GamesItem h3 {
        font-size: 20px;
    }
}

@media all and (max-width: 900px) {
    .GamesItem {
        font-size: 14px;
    }

    .GamesImage img {
        max-width: 220px;
    }
}

@media all and (max-width: 800px) {
    .GamesImage img {
        max-width: 220px;
    }
}

@media all and (max-width: 700px) {
    .GamesItem:nth-child(odd) .GamesImage {
        order: 0;
    }

    .GamesFlex {
        flex-direction: column;
    }

    .GamesImage {
        padding-bottom: 20px;
    }

    .GamesResponsive {
        width: 100% !important;
    }

    .GamesItem p,
    .GamesItem h3 {
       text-align: center;
    }

}

@media all and (max-width: 400px) {
    .GamesImage img {
        max-width: 180px;
    }

    .GamesItem h3 {
        font-size: 18px;
    }
}