body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
body {
  min-width: 320px;
  overflow-x: hidden;
}
body,
html {
  font-family: 'Titillium Web', sans-serif !important;
  font-weight: 300;
  width: 100%;
  height: 100%;
  color: #000038;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1,h2,h3,h4,h5 {
  font-weight: 600;
  color: #000038;
}
h2,h3 {
  text-transform: uppercase;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.main{
  overflow-x: hidden;
}

.wrapperHeader {
  max-width: 2400px;
  margin: 0 auto;
  padding: 80px 40px;
  margin-bottom: 560px;
}

@media (max-width: 991px) {
  .wrapperHeader {
    margin-bottom: 300px;
    padding: 80px 0;
    width: 100vw;
    text-align: center;
  }
}
