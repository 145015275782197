.Header {
    display: table;
    width: 100%;
    padding: 0;
    background: url("../../img/background2.jpg") center center no-repeat;
    background-color: #e5e5e5;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}
.Header .HeaderTitle {
    padding: 300px 0 250px;
    max-width: 650px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
}


.Header h1 {
    color: #fff;
    font-size: 82px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 10px;
    position: absolute;
    top:200px;
}

.headerInfo {
    padding-right: 40px;
}

@media (max-width: 991px) {
    .headerInfo {
        display: none;
    }
}

.headerInfo h2 {
    font-size: 50px;
    color: white;
}

.headerInfo p {
    font-size: 20px;
    margin-top:100px; 
    color: white;
}

.headerInfo button {
    width: 200px;
    height: 60px;
    background: radial-gradient(88.7% 124.43% at 50% 0%, #FFFFFF 0%, #E6E6E6 38.02%, #595959 100%);
    border-radius: 6px;
    font-size: 24px;
    color: #000;
    margin-top: 100px;
}

.headerInfo button:hover {   
        background: radial-gradient(88.7% 124.43% at 50% 0%, #FFFFFF 0%, #E6E6E6 55%, #595959 100%);
}


@media all and (max-width: 1200px) {
    .Header h1 {
        font-size: 70px;
    }
}

@media all and (max-width: 980px) {
    .Header h1 {
        font-size: 55px;
    }

    .Header .HeaderTitle {
        max-width: 530px;
    }
}

@media all and (max-width: 690px) {
    .Header .HeaderTitle {
        padding: 200px 0 150px;
    }
}

@media all and (max-width: 590px) {
    .Header h1 {
        font-size: 40px;
    }

    .Header .HeaderTitle {
        max-width: 330px;
    }
}

@media all and (max-width: 400px) {
    .Header h1 {
        font-size: 37px;
    }

    .Header .HeaderTitle {
        max-width: 300px;
    }
}