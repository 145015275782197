.screenshotImg {
    width: 1064px;
    height: 598px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    border-radius: 10px;
    user-select: none;
}

@media (max-width: 1200px) {
    .screenshotImg {
        width: 1000px;
        height: auto;
    }
}

@media (max-width: 1024px) {
    .screenshotImg {
        width: 700px;
        height: auto;
    }
}

@media (max-width: 900px) {
    .screenshotImg {
        width: 600px;
        height: auto;
    }
}

@media (max-width: 768px) {
    .screenshotImg {
        width: 500px;
        height: auto;
    }
}
@media (max-width: 600px) {
    .screenshotImg {
        width: 460px;
        height: auto;
    }
}

@media (max-width: 480px) {
    .screenshotImg {
        width: 400px;
        height: auto;
    }
}

@media (max-width: 320px) {
    .screenshotImg {
        width: 260px;
        height: auto;
    }
}

