.NavFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.NavFlex a {
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
}

.NavFlex a:hover {
    color: #101010;
}

@media all and (max-width: 690px) {
    .NavFlex a {
        font-size: 14px;
    }
}

@media all and (max-width: 590px) {
    .NavFlex a {
        font-size: 12px;
    }
}

@media all and (max-width: 490px) {
    .NavFlex a {
        padding: 8px !important;
    }
}

@media all and (max-width: 400px) {
    .NavFlex a {
        font-size: 10px;
        padding: 6px !important;
    }
}