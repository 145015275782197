.Button {
    width: 100%;
    max-width: 200px;
    padding: 12px 0px;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    background: rgb(128, 50, 239);
    border: none;
    text-transform: uppercase;
    margin: 0 auto;
}

.Button:hover {
    background: rgb(97, 18, 210);
}

@media all and (max-width: 700px) {
    .Button {
        max-width: 150px;
        font-size: 14px;
    }
}

@media all and (max-width: 400px) {
    .Button {
        max-width: 130px;
        font-size: 12px;
    }
}