.Contact {
    color: #000038;
    padding: 30px 0;
    font-size: 15px;
    background: #f6f6f6;
}

.Contact h2,
.Contact h3 {
    color: #000038;
}

.Contact h2 {
    position: relative;
    padding-bottom: 15px;
}

.Contact h2::after {
    position: absolute;
    content: "";
    background: #000038;
    height: 4px;
    width: 60px;
    bottom: 0;
    left: 30px;
    margin-left: -30px;
}

.ContactSection {
    padding-bottom: 30px;
}

@media screen and (max-width: 900px) {
    .Contact h2 {
        font-size: 25px;
    }

    .Contact h3 {
        font-size: 20px;
    }

    .Contact {
        font-size: 14px;
    }
}

@media screen and (max-width: 400px) {
    .Contact h2 {
        font-size: 22px;
    }

    .Contact h3 {
        font-size: 18px;
    }

    .Contact {
        font-size: 12px;
    }
}