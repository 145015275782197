.Services {
    font-size: 15px;
    padding: 30px 0;
    background: #f6f6f6;
}

.Services p {
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 20px;
}

.Services svg {
    font-size: 45px;
}

.Services h2 {
    position: relative;
    padding-bottom: 20px;
}

.Services h2::after {
    position: absolute;
    content: "";
    background: #000038;
    height: 4px;
    width: 60px;
    bottom: 0;
    margin-left: -30px;
    left: 50%;
}

@media all and (max-width: 1050px) {
    .Services svg {
        font-size: 40px;
    }
}

@media all and (max-width: 900px) {
    .Services h2 {
        font-size: 25px;
    }

    .Services h3 {
        font-size: 20px;
    }
}