.Footer {
    background: #0e0e0e;
    padding: 30px 0;
}

.Footer p {
    color: #fff;
    font-size: 14px;
    margin: 0 !important;
}

@media all and (max-width: 900px) {
    .Footer {
        padding: 20px 0;
    }
}

@media all and (max-width: 400px) {
    .Footer {
        padding: 10px 0;
    }

    .Footer p {
        font-size: 12px;
    }
}