.About {
    background: #fff;
    padding: 70px 0;
}

.AboutContent {
    display: flex;
    align-items: center;
    color: #000038;
}

.AboutResponsive {}

.AboutText {
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    padding: 30px;
    background: #efefef;
    font-size: 15px;
}

.AboutImage {
    max-width: 450px;
    margin: 0 auto;
}

.About h2 {
    color: #000038;
}

@media all and (max-width: 1200px) {
    .About {
        padding: 50px 0;
    }

    .AboutImage {
        max-width: 350px;
    }

    .AboutText {
        padding: 20px;
    }
}

@media all and (max-width: 1050px) {
    .AboutContent {
        flex-direction: column;
    }

    .AboutImage {
        padding-bottom: 50px;
    }

    .AboutResponsive {
        width: 100% !important;
    }
}

@media all and (max-width: 900px) {
    .About {
        padding: 30px 0;
    }

    .AboutImage {
        max-width: 250px;
        padding-bottom: 30px;
    }

    .AboutText {
        padding: 20px;
        font-size: 14px;
    }

    .About h2 {
        font-size: 20px;
    }
}

@media all and (max-width: 700px) {
    .About {
        padding: 30px 0px;
    }
}

@media all and (max-width: 700px) {
    .AboutImage {
        max-width: 200px;
    }
}
