.Games {
    background: #0e0e0e;
    color: #fff;
}

.Games h2,
.Games h3 {
    color: #e08aff;
}

.Games h2 {
    text-align: center;
    padding: 20px 10px;
}

.GamesButtonContainer {
    margin: 0 auto;
    padding: 30px 0;
    text-align: center;
    max-width: 300px;
}

@media all and (max-width: 900px) {
    .Games h2 {
        font-size: 25px;
    }
}

@media all and (max-width: 400px) {
    .Games h2 {
        font-size: 22px;
    }
}